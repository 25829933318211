import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useFetch } from '../../../helpers/hooks/fetchData';
import { StandardTable } from '../../../components/shared/standard-table/StandardTable';
import { SearchBar } from '../../../components/shared/search-bar/SearchBar';
import { Button } from '../../../components/shared/button/Button';
import { AddCandidate } from '../../../components/admin/candidates/AddCandidate';
import { device } from '../../../styles/device';
import { ProfileCard } from '../../../components/admin/candidates/ProfileCard';
import { NoResultsFound } from '../../../components/shared/error-page-components/NoResultsFound';
import { userHasAccess } from '../../../helpers/permissions';

//set as environment variable later
const itemsPerPage = 25;

export const ViewAllCandidates = (props) => {
  const [payload, setPayload] = useState({});
  const endpoint = '/api/omega/users';
  const method = 'get';
  const [users, setUsers] = useState([]);
  const [moreUsersAvailable, setMoreUsersAvailable] = useState(false);
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [params, setParams] = useState({
    searchString: '',
    sortPropName: '',
    sortHeading: null,
    skip: 0,
    limit: itemsPerPage,
  });
  const [activeButton, setActiveButton] = useState('cards');

  const { data, isLoading } = useFetch(payload);

  useEffect(() => {
    setPayload({ endpoint, method, params });
  }, [params, endpoint, method]);

  useEffect(() => {
    setUpdateInProgress(false);
    const newUsers = (data && data.data) || [];
    setUsers((users) => {
      return [...users, ...newUsers];
    });
  }, [data]);

  useEffect(() => {
    const count = data && data.count;
    const moreUsersAvailable = count && params.limit + params.skip < count;
    setMoreUsersAvailable(moreUsersAvailable);
  }, [data, params.limit, params.skip]);

  /**
   * Set sort name and heading of sort attribute.
   * @param {String} propName
   */
  const sortByProp = (propName) => {
    setUpdateInProgress(true);
    if (!propName) return false;
    //clear users array
    setUsers([]);
    const skip = params.sortPropName !== propName ? 0 : params.skip;

    //if third press on same heading, clear the sorting
    if (params.sortHeading === -1 && propName === params.sortPropName) {
      return setParams({ ...params, sortHeading: '', sortPropName: '', skip });
    }

    //if not cleared, heading will be set to 1 on first click and -1 on second click
    const sortHeading =
      propName === params.sortPropName && params.sortHeading === 1 ? -1 : 1;
    const sortPropName = propName;
    return setParams({ ...params, sortHeading, sortPropName, skip });
  };

  const searchEntered = (s) => {
    setUpdateInProgress(true);
    //clear users array
    setUsers([]);
    const skip = 0;
    return setParams({ ...params, searchString: s, skip });
  };

  const navigateToCandidatePage = (slug) => {
    return props.history.push('/admin/candidates/' + slug);
  };

  const showMore = () => {
    const skip = params.skip + itemsPerPage;
    return setParams({ ...params, skip });
  };

  return (
    <>
      <SearchBar
        searchString={params.searchString}
        onChange={searchEntered}
        placeholder={'Search'}
        viewSwitch
        setActiveButton={setActiveButton}
      />
      {activeButton === 'list' && (
        <ListContainer>
          {users.length > 0 && (
            <StandardTable
              type='users'
              items={users}
              onHeaderClick={sortByProp}
              onItemClick={navigateToCandidatePage}
            />
          )}
          {!updateInProgress && users.length === 0 && !isLoading && (
            <NoResultsFound />
          )}
        </ListContainer>
      )}
      {activeButton === 'cards' && (
        <CardsContent>
          {users.length === 0 && !isLoading && <NoResultsFound />}
          <CardsContainer>
            <ProfileCard users={users} onItemClick={navigateToCandidatePage} />
          </CardsContainer>
        </CardsContent>
      )}
      {userHasAccess('CANDIDATES') === 'crud' && (
        <AddCandidate history={props.history} />
      )}
      {!!moreUsersAvailable && (
        <ShowMoreContainer>
          <ButtonWidth>
            <Button onClick={showMore} text='Show more' primary />
          </ButtonWidth>
        </ShowMoreContainer>
      )}
    </>
  );
};

const ListContainer = styled.div`
  width: 100%;
  margin-bottom: 7rem;
`;

const ShowMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 7rem;
`;

const ButtonWidth = styled.div`
  width: 20rem;
`;

const CardsContent = styled.main`
  width: 100%;
  margin-top: ${(props) => props.theme.spacing.extraSmall};
`;

const CardsContainer = styled.div`
  width: 100%;
  padding-left: ${(props) => props.theme.spacing.extraLarge};
  padding-right: ${(props) => props.theme.spacing.extraLarge};
  margin-top: ${(props) => props.theme.spacing.extraLarge};
  margin-bottom: ${(props) => props.theme.spacing.extraLarge};
  display: grid;
  justify-items: start;
  grid-template-columns: repeat(auto-fit, minmax(32rem, 1fr));
  grid-column-gap: ${(props) => props.theme.spacing.standard};
  grid-row-gap: 6.5rem;

  @media (max-width: 1104px) {
    grid-column-gap: ${(props) => props.theme.spacing.extraSmall};
    padding-right: ${(props) => props.theme.spacing.extraSmall};
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  }
  @media (max-width: 979px) {
    grid-column-gap: ${(props) => props.theme.spacing.small};
  }
  @media (max-width: 868px) {
    padding-right: ${(props) => props.theme.spacing.standard};
  }
  @media ${device.tablet} {
    padding-right: ${(props) => props.theme.spacing.extraSmall};
  }
  @media (max-width: 725px) {
    padding-right: 0.5rem;
  }
  @media ${device.mobileL} {
    padding-left: ${(props) => props.theme.spacing.small};
    padding-right: ${(props) => props.theme.spacing.small};
  }
`;
