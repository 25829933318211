import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { InputWrapper } from '../../../shared/input/InputWrapper';
import { Formik } from 'formik';
import { closedSection } from '../../../shared/assets/icons';
import { InputField } from '../../../shared/input/InputField';
import { useFetch } from '../../../../helpers/hooks/fetchData';
import {
  LanguageItem,
  LanguageItemWrapper,
} from '../../questions/edit-question/LanguageItem';
import { AddNewGroup } from '../../templates/edit-template';
import { Languages } from '../../questions/edit-question/Languages';
import { SelectInput } from '../../../shared/input/SelectInput';
import { EditScatterplotRow } from './EditScatterplotRow';
import { EditPersonalityScore } from './EditPersonalityScore';
import { Button } from '../../../shared/button/Button';
import { templateTypes } from './reportHelper';
import { EditFourFielder } from './EditFourFielder';

export const EditReportTemplate = ({
  title,
  setTitle,
  availableLanguages,
  setAvailableLanguages,
  sections,
  setSections,
  sectionOpen,
  setSectionOpen,
  clusters,
  setSubmitPayload,
}) => {
  const [languagePayload] = useState({ endpoint: '/api/omega/languages/' });
  const [languages, setLanguages] = useState(null);

  const { data: languagesData } = useFetch(languagePayload);

  useEffect(() => {
    if (languagesData && languagesData.data) {
      setLanguages(languagesData.data);
    }
  }, [languagesData]);

  const addLanguage = (language) => {
    const newAvailableLanguages = availableLanguages;
    const langIx = newAvailableLanguages.findIndex(
      (availableLanguage) => availableLanguage.code === language.code
    );

    if (langIx === -1) {
      newAvailableLanguages.push(language);
    } else {
      newAvailableLanguages.splice(langIx, 1);
    }
    setAvailableLanguages([...newAvailableLanguages]);
  };

  const addNewSection = () => {
    setSections([
      ...sections,
      {
        title: {},
        median: false,
        singleData: false,
        type: 0,
        data: {},
      },
    ]);
    setSectionOpen(sections.length + 1);
  };

  const handleChangeSectionType = (target, index) => {
    const value = (target.value && parseInt(target.value)) || 0;

    switch (value) {
      case 0:
        sections[index].data = {};
        break;
      case 1:
        sections[index].data = {
          scatterplotRows: [
            {
              highValue: {},
              lowValue: {},
              cluster: '',
            },
          ],
        };
        break;
      case 2:
        sections[index].data = {
          fourFielder: {
            x: { cluster: '', highValue: {}, lowValue: {} },
            y: { cluster: '', highValue: {}, lowValue: {} },
          },
        };
        break;
      case 3:
      case 4:
      case 5:
        sections[index].data = {
          personalityScore: [''],
        };
        break;

      default:
        break;
    }

    sections[index].type = value;
    setSections([...sections]);
  };

  const removeSection = (ix) => {
    sections.splice(ix, 1);
    setSections([...sections]);
    setSectionOpen(ix);
  };

  const openSection = (ix) => {
    return setSectionOpen(ix);
  };

  const renderSelectedType = (
    errors,
    handleBlur,
    touched,
    section,
    sectionIndex
  ) => {
    switch (section.type) {
      case 1:
        return (
          <EditScatterplotRow
            sections={sections}
            setSections={setSections}
            errors={errors}
            handleBlur={handleBlur}
            touched={touched}
            section={section}
            sectionIndex={sectionIndex}
            clusters={clusters}
            availableLanguages={availableLanguages}
          />
        );
      case 2:
        return (
          <EditFourFielder
            sections={sections}
            setSections={setSections}
            errors={errors}
            handleBlur={handleBlur}
            touched={touched}
            section={section}
            sectionIndex={sectionIndex}
            clusters={clusters}
            availableLanguages={availableLanguages}
          />
        );
      case 3:
      case 4:
      case 5:
        return (
          <EditPersonalityScore
            sections={sections}
            setSections={setSections}
            errors={errors}
            handleBlur={handleBlur}
            touched={touched}
            section={section}
            sectionIndex={sectionIndex}
            clusters={clusters}
            availableLanguages={availableLanguages}
          />
        );

      default:
        return null;
    }
  };

  const handleSectionChange = (target, index, lang, option) => {
    switch (option) {
      case 'title':
        if (!sections[index].title[lang.code])
          sections[index].title[lang.code] = {};
        sections[index].title[lang.code] = target.value;
        break;
      default:
        break;
    }

    setSections([...sections]);
  };

  return (
    <Container>
      <InnerContainer>
        <Formik enableReinitialize={true}>
          {({ touched, errors, handleBlur }) => (
            <Form onSubmit={setSubmitPayload}>
              {((sectionOpen === -1 || sectionOpen === 0) && (
                <>
                  <React.Fragment key={0}>
                    <InputWrapper marginTop noPadding>
                      <InputWrapper noPadding noPaddingLeft title='Language'>
                        <LanguageItemWrapper>
                          {languages &&
                            languages.length > 0 &&
                            languages.map((language, key) => (
                              <LanguageItem
                                key={key}
                                onClick={() => addLanguage(language, key)}
                                code={language.code}
                                flag={language.flag}
                                active={availableLanguages.some(
                                  (pickedLanguage) =>
                                    pickedLanguage._id === language._id
                                )}
                              />
                            ))}
                        </LanguageItemWrapper>
                      </InputWrapper>
                      <InputField
                        label='Template title'
                        value={title}
                        type='string'
                        onChange={({ target }) => setTitle(target.value)}
                        required
                      />
                    </InputWrapper>
                  </React.Fragment>
                </>
              )) || (
                <React.Fragment key={0}>
                  <AddGroupContainer marginTop onClick={() => openSection(0)}>
                    <Icon src={closedSection} />
                    <Text>Title & Language</Text>
                  </AddGroupContainer>
                </React.Fragment>
              )}
              {sections &&
                sections.map((section, sectionIndex) => {
                  if (sectionOpen === sectionIndex + 1) {
                    return (
                      <InputWrapper marginTop noPadding key={sectionIndex}>
                        <Languages
                          availableLanguages={availableLanguages}
                          errors={errors}
                          touched={touched}
                          values={section}
                          sectionIndex={sectionIndex}
                          onChange={handleSectionChange}
                          onBlur={handleBlur}
                          reportSectionTitle
                        />
                        <SelectInput
                          label='Section type'
                          placeholder='Select type'
                          // TODO: fix a better options
                          data={templateTypes}
                          onChange={({ target }) =>
                            handleChangeSectionType(target, sectionIndex)
                          }
                          value={section.type}
                        />
                        {renderSelectedType(
                          errors,
                          handleBlur,
                          touched,
                          section,
                          sectionIndex
                        )}
                        <ButtonContainer>
                          <Button
                            warning
                            type='button'
                            text='Remove section'
                            onClick={() => removeSection(sectionIndex)}
                          />
                        </ButtonContainer>
                      </InputWrapper>
                    );
                  } else {
                    let text = '';
                    let altKey = Object.keys(section.title)[0];

                    text =
                      (section.title['EN'] && section.title['EN']) ||
                      (section.title[altKey] && section.title[altKey]) ||
                      '';

                    return (
                      <React.Fragment key={`${sectionIndex}`}>
                        <AddGroupContainer
                          marginTop
                          onClick={() => openSection(sectionIndex + 1)}
                        >
                          <Icon src={closedSection} />

                          <Text>{text}</Text>
                        </AddGroupContainer>
                      </React.Fragment>
                    );
                  }
                })}
              <StandardSpacing />

              <AddNewGroup
                onClick={addNewSection}
                title='Add new section'
                isDisable={availableLanguages.length ? false : true}
              />
              <StandardSpacing />
            </Form>
          )}
        </Formik>
      </InnerContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;
const InnerContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 804px;
`;

const StandardSpacing = styled.div`
  height: ${(props) => props.theme.spacing.standard};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Text = styled.p`
  padding-left: ${(props) => props.theme.spacing.standard};
  font-family: ${(props) => props.theme.font.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.9rem;
`;

const Icon = styled.img`
  height: 2rem;
  width: 2rem;
  margin-left: ${(props) => props.theme.spacing.standard};
  ${(props) => props.alignEnd && 'margin-left: auto;'}
  ${(props) => props.onClick && 'cursor: pointer;'}
`;

const AddGroupContainer = styled.div`
  height: 6rem;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 0.4rem;
  background: ${(props) => props.theme.color.white};
  cursor: pointer;
  ${(props) =>
    props.marginTop &&
    css`
      margin-top: ${(props) => props.theme.spacing.standard};
    `}
  ${(props) =>
    props.marginBottom &&
    css`
      margin-bottom: ${(props) => props.theme.spacing.standard};
    `}
  ${(props) =>
    props.errorMessage &&
    css`
      border: 1px solid ${(props) => props.theme.color.error};
    `}
`;

const ButtonContainer = styled.div`
  width: 50%;
  margin-left: auto;
  margin-top: 2rem;
`;
