import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { device } from '../../../styles/device';

export const CreationProgress = ({
  step,
  firstStepText,
  secondStepText,
  thirdStepText,
}) => {
  return (
    <Container>
      <EntireBar>
        <BlobContainer>
          <Blob step={step} />
          <Blob step={step} />
          <Blob step={step} />
        </BlobContainer>
        <ProgressStepBar step={step} />
      </EntireBar>
      <TextContainer>
        <Text step={step}>{firstStepText}</Text>
        <Text step={step}>{secondStepText}</Text>
        <Text step={step}>{thirdStepText}</Text>
      </TextContainer>
    </Container>
  );
};

const step2 = keyframes`
  0% {
    width: 0%;
  }
  50% {
    width: 50%;
  }
`;

const step3 = keyframes`
  0% {
    width: 50%;
  }
  50% {
    width: 100%;
  }
`;

const blobColorChange = keyframes`
  from {
    background: #d6d6d6
  }
  to {
    background: #4083BF
  }
`;

const textColorChange = keyframes`
  0% {
    color: #d6d6d6
  }
  100% {
    color: #000000
  }
`;

const Container = styled.div`
  background: ${(props) => props.theme.color.white};
  height: 5rem;
  border-radius: 0.4rem;
  @media ${device.tablet} {
    height: 4rem;
  }
`;

const EntireBar = styled.div`
  height: 0.4rem;
  background: ${(props) => props.theme.color.mediumGrey};
  position: relative;
  margin: 0 7rem;
  @media ${device.tablet} {
    top: 50%;
    margin: 0 1.5rem;
  }
`;

const ProgressStepBar = styled.div`
  height: 4px;
  background: ${(props) => props.theme.color.mediumGrey};
  position: absolute;
  top: 0;
  left: 0;
  ${(props) =>
    props.step === 'second' &&
    css`
      animation: 3s ${step2} ease-in;
      background: ${(props) => props.theme.color.primary};
      width: 50%;
    `}
  ${(props) =>
    props.step === 'third' &&
    css`
      animation: 3s ${step3} ease-in;
      background: ${(props) => props.theme.color.primary};
      width: 100%;
    `}
`;

const TextContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 4rem;
  @media ${device.tablet} {
    display: none;
  }
`;

const BlobContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  z-index: 1000;
  position: absolute;
  top: -1.2rem;
`;

const Blob = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  background: ${(props) => props.theme.color.mediumGrey};
  box-shadow: 0px 0.05rem 1rem rgba(0, 0, 0, 0.12);
  &:first-child {
    background: ${(props) => props.theme.color.primary};
    margin-left: -0.5rem;
  }
  ${(props) =>
    props.step === 'second' &&
    css`
      &:nth-child(2) {
        animation: 1s ${blobColorChange} ease;
        animation-delay: 1.4s;
        animation-fill-mode: forwards;
      }
    `}
  ${(props) =>
    props.step === 'third' &&
    css`
      &:nth-child(2) {
        background: ${(props) => props.theme.color.primary};
      }
      &:nth-child(3) {
        animation: 1s ${blobColorChange} ease;
        animation-delay: 1.4s;
        animation-fill-mode: forwards;
      }
    `}
    &:last-child {
    margin-right: -0.5rem;
  }
`;

const Text = styled.p`
  font-family: ${(props) => props.theme.font.primary};
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.color.mediumGrey};
  &:first-child {
    color: ${(props) => props.theme.color.black};
  }
  ${(props) =>
    props.step === 'second' &&
    css`
      &:nth-child(2) {
        animation: 1s ${textColorChange} ease;
        animation-delay: 1.4s;
        animation-fill-mode: forwards;
      }
    `}
  ${(props) =>
    props.step === 'third' &&
    css`
      &:nth-child(2) {
        color: ${(props) => props.theme.color.black};
      }
      &:nth-child(3) {
        animation: 1s ${textColorChange} ease;
        animation-delay: 1.4s;
        animation-fill-mode: forwards;
      }
    `}
`;
