import React, { useEffect, useState } from 'react';
import { useFetch } from '../../../helpers/hooks/fetchData';
import styled, { css } from 'styled-components';
import * as Yup from 'yup';
import { Button } from '../landing/Button';
import { Formik } from 'formik';

export const ForgetPasswordForm = ({ toggleForm }) => {
  const [payload, setPayload] = useState({});
  const [sentEmail, setSentEmail] = useState(false);
  const { data } = useFetch(payload);

  useEffect(() => {
    if (data) {
      setSentEmail(true);
    }
  }, [data]);

  const validationSchemaForgetPassword = Yup.object().shape({
    email: Yup.string().required('Required'),
  });

  return (
    <>
      <Container>
        <Formik
          validationSchema={validationSchemaForgetPassword}
          initialValues={{ email: '' }}
          onSubmit={(values) => {
            setPayload({
              method: 'post',
              endpoint: '/api/omega/candidate-public/change-password',
              data: {
                email: values.email,
                isOmegaRequest: true,
              },
            });
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Label disabled={sentEmail}>Email </Label>
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                type='email'
                value={values.email}
                id='email'
                disabled={sentEmail && 'disabled'}
              />
              <ErrorAndToggleContainer>
                {errors.password && touched.password ? (
                  <ErrorMessage>{errors.password}</ErrorMessage>
                ) : (
                  <ErrorMessage />
                )}
                <ForgetPassword>
                  <p onClick={toggleForm}>Back to log in</p>
                </ForgetPassword>
              </ErrorAndToggleContainer>
              {sentEmail ? (
                <SentText>Perfect, your e-mail will arrive shortly!</SentText>
              ) : (
                <ButtonContainer>
                  <Button type='submit' text='Send' />
                </ButtonContainer>
              )}
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
};

export const SignInForm = ({ toggleForm }) => {
  const [payload, setPayload] = useState({});
  const { data, hasError } = useFetch(payload);

  useEffect(() => {
    if (data) {
      window.location.assign('/');
    }
  }, [data]);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Required'),
    password: Yup.string().required('Required'),
  });

  return (
    <>
      <Container>
        {hasError && (
          <>
            <ErrorMessage>
              Please reload the page and try again. If the issue persists, check
              your credentials and try again.{' '}
            </ErrorMessage>
            <ButtonContainer>
              <Button
                type='button'
                text='Reload'
                onClick={() => window.location.reload()}
              />
            </ButtonContainer>
          </>
        )}
        <Formik
          validationSchema={validationSchema}
          initialValues={{ email: '', password: '' }}
          onSubmit={(values) => {
            setPayload({
              method: 'post',
              endpoint: '/login',
              data: {
                email: values.email,
                password: values.password,
                isOmegaRequest: true,
              },
            });
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Label>Email </Label>
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                type='email'
                value={values.email}
                id='email'
              />
              {errors.email && touched.email ? (
                <ErrorMessage>{errors.email}</ErrorMessage>
              ) : (
                <ErrorMessage />
              )}
              <Label>Password </Label>
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                type='password'
                value={values.password}
                name='password'
                id='password'
              />
              <ErrorAndToggleContainer>
                {errors.password && touched.password ? (
                  <ErrorMessage inBottomContainer>
                    {errors.password}
                  </ErrorMessage>
                ) : (
                  <ErrorMessage />
                )}
                <ForgetPassword>
                  <p onClick={toggleForm}>Forgot password</p>
                </ForgetPassword>
              </ErrorAndToggleContainer>
              <ButtonContainer>
                <Button type='submit' text='Sign in' />
              </ButtonContainer>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 160%;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ErrorAndToggleContainer = styled.div`
  width: 100%;
  display: flex;
`;

const ForgetPassword = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;

  font-weight: normal;
  font-size: 2.2rem;
  line-height: 160%;
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  & p {
    cursor: pointer;
  }
`;

const ErrorMessage = styled.div`
  width: ${(props) => (props.inBottomContainer ? '50%' : '100%')};
  min-height: 3.5rem;
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 2.2rem;
  line-height: 160%;
  color: ${(props) => props.theme.color.error};
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: ${(props) => props.theme.spacing.standard};
  margin-top: 3rem;
`;

const Label = styled.label`
  margin-top: 2.5rem;
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 160%;
  color: ${(props) =>
    props.disabled ? props.theme.color.mediumGrey : props.theme.color.black};
`;

const Input = styled.input`
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 2.2rem;
  height: 5rem;
  width: 100%;
  border: 0rem;
  color: ${(props) =>
    props.disabled ? props.theme.color.mediumGrey : props.theme.color.black};
  border-bottom: 0.1rem solid
    ${(props) =>
      props.disabled ? props.theme.color.mediumGrey : props.theme.color.black};
  margin-bottom: 0.5rem;
  ${(props) =>
    props.isError &&
    css`
      border-bottom: 0.1rem solid ${(props) => props.theme.color.error};
    `}
`;

const SentText = styled.p`
  font-family: 'futura-pt', sans-serif;
  font-weight: normal;
  font-size: 2.2rem;
  line-height: 160%;

  letter-spacing: 0.01em;
`;
