import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Button } from '../../../shared/button/Button';
import { Modal } from '../../../shared/modal/Modal';
import { copyToClipBoard } from '../../../../helpers/common';
import { FaCheck } from 'react-icons/fa';

export const CopyTokenLinkModal = ({
  users,
  showModal,
  handleExit,
  singleUser = false,
}) => {
  const [copied, setCopied] = useState(
    (users && new Array(users.length).fill(false)) || []
  );

  const copyTokenToClipBoard = (link, index) => {
    copyToClipBoard(link);

    const newCopied = new Array(users.length).fill(false);
    newCopied[index] = true;
    setCopied(newCopied);
  };

  return (
    <Modal
      handleExit={handleExit}
      title='Copy access link to clipboard'
      show={showModal}
      width='100rem'
    >
      <List>
        {users &&
          users.length &&
          users.map((user, index) => {
            const link = `https://omega.navigio.se/omega/choose-password/?token=${user.omegaCandidateAccessToken}`;

            return (
              <ListItem key={index} singleUser={singleUser}>
                <Text singleUser={singleUser}>{user.name} </Text>
                <Text singleUser={singleUser}>{link}</Text>
                <CopyTokenButton
                  onClick={() => {
                    copyTokenToClipBoard(link, index);
                  }}
                >
                  {(copied[index] && <Check />) || 'Copy link'}
                </CopyTokenButton>
              </ListItem>
            );
          })}
      </List>

      <ButtonContainer>
        <Button onClick={handleExit} secondary text='Ok' />
      </ButtonContainer>
    </Modal>
  );
};
export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const List = styled.ul`
  width: 100%;
  display: block;
`;

const ListItem = styled.li`
  display: grid;
  grid-template-columns: ${(props) =>
    props.singleUser ? '1fr 0.2fr' : '0.3fr 1fr 0.2fr'};
  width: 100%;
  align-items: center;
  font-size: 1.6rem;
  margin-top: 0.5rem;
`;

const Text = styled.p`
  font-size: 1.6rem;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: auto;

  ${(props) =>
    props.singleUser
      ? css`
          &:first-child {
            display: none;
          }
        `
      : css`
          &:first-child::first-letter {
            text-transform: uppercase;
          }
        `}

  &:not(:first-child) {
    margin-left: 1.5rem;
  }
`;

const Check = styled(FaCheck)`
  margin-left: auto;
  margin-right: 0;
`;

const ButtonContainer = styled.div`
  margin-top: ${(props) =>
    props.small ? props.theme.spacing.small : props.theme.spacing.extraLarge};
  min-widht: fit-content;
  width: 30%;
  margin-right: auto;
  margin-left: auto;
`;

const CopyTokenButton = styled.button`
  padding: 0 6px;
  border-radius: 0.4rem;
  font-weight: 600;
  font-family: ${(props) => props.theme.font.primary};
  line-height: 1.9rem;
  background-color: #2584c4;
  color: #fff;
  margin-left: auto;
  margin-right: 0;
  cursor: pointer;
  border-color: #2584c4;
  min-width: 8.5rem;

  &:active {
    background-color: #2980b9;
    border-color: white;
  }
`;
