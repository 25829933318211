import React from 'react';
import styled from 'styled-components';
import { device } from '../../../../styles/device';
import {
  optionsOnlyGrey,
  optionsOnlyWhite,
} from '../../../shared/assets/icons';

export const TimeLimit = ({
  hasTimeLimit,
  setHasTimeLimit,
  setTimeLimit,
  onBlur,
  onChange,
  value,
  error,
  // message,
}) => {
  const handleClick = () => {
    if (hasTimeLimit) {
      setTimeLimit('00:00:00');
    }
    setHasTimeLimit(!hasTimeLimit);
  };
  const checkBoolean = () => {
    if (!hasTimeLimit) {
      setHasTimeLimit(true);
    }
  };

  return (
    <Container>
      <TopContainer>
        <InputContainer>
          <LeftLabel>
            <Label htmlFor='hours'>Hours</Label>
          </LeftLabel>
          <InputCenter>
            <Input
              id='timeLimit'
              type='time'
              step='1'
              onChange={(e) => {
                setHasTimeLimit(true);
                onChange(e);
              }}
              onKeyUp={() => checkBoolean()}
              onBlur={onBlur}
              name='timeLimit'
              value={value || '00:00:00'}
              error={error}
              min='00:00:00'
            />
          </InputCenter>
          <RightLabel>
            <Label>Minutes</Label>
          </RightLabel>
        </InputContainer>

        {/* <ErrorContainer>
          {message && <ErrorMessage>{message}</ErrorMessage>}
        </ErrorContainer> */}
      </TopContainer>
      <BottomContainer onClick={() => handleClick()} timeLimit={hasTimeLimit}>
        {hasTimeLimit ? (
          <Icon src={optionsOnlyGrey} />
        ) : (
          <Icon src={optionsOnlyWhite} />
        )}
        <Text timeLimit={hasTimeLimit}>No time limit</Text>
      </BottomContainer>
    </Container>
  );
};

const Container = styled.div``;

const TopContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.theme.spacing.large};
  margin-bottom: ${(props) => props.theme.spacing.large};
`;
const BottomContainer = styled.div`
  height: ${(props) => props.theme.spacing.extraLarge};
  // background: ${(props) => props.theme.color.bgGrey};
  border-radius: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${(props) =>
    props.timeLimit
      ? `${props.theme.color.bgGrey}`
      : `${props.theme.color.selectedGrey}`};
`;
const LeftLabel = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: ${(props) => props.theme.spacing.small};
`;
const RightLabel = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: ${(props) => props.theme.spacing.small};
`;
const InputCenter = styled.div``;
const Input = styled.input`
  border-radius: 0.4rem;
  width: 12rem;
  font-family: '${(props) => props.theme.font.primary}';
  background: ${(props) => props.theme.color.bgGrey};
  font-size: 1.6rem;
  height: ${(props) => props.theme.spacing.extraLarge};
  border: none;
  padding: ${(props) => props.theme.spacing.extraSmall};
  text-align: center;

  color: ${(props) => props.theme.color.textGrey};
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-clear-button {
    display: none;
  }
  &::-ms-clear {
    display: none;
  }
`;
const Label = styled.label`
  font-size: 1.6rem;
  font-weight: bold;
  @media ${device.mobileL} {
    font-size: 1.4rem;
  }
`;
const Icon = styled.img``;
const Text = styled.span`
  font-size: 1.6rem;
  margin-left: ${(props) => props.theme.spacing.small};
  color: ${(props) =>
    props.timeLimit
      ? `${props.theme.color.textGrey}`
      : `${props.theme.color.white}`};
  @media ${device.mobileL} {
    font-size: 1.4rem;
  }
`;
// const ErrorMessage = styled.span`
//   color: ${(props) => props.theme.color.error};
//   font-size: 1.3rem;
//   padding-bottom: 0.5rem;
// `;
// const ErrorContainer = styled.div`
//   width: 100%;
// `;
const InputContainer = styled.div`
  width: 100%;
  display: flex;
`;
