import React, { useEffect, useRef, useState, useContext } from 'react';
import { ProgressBarTimer } from './ProgressBarTimer';
import { ContentContext } from '../../../helpers/contentContext';

import styled, { css } from 'styled-components';
import { device } from '../../../styles/device';

/**
 * Calculates the progress percentage based on the total number of pages and the current progress.
 * @param {number} totalNumberOfPages - Total number of pages in the progress.
 * @param {number} barProgress - Current progress value.
 * @returns {number} - The calculated progress percentage.
 *                    Returns -1 if barProgress is not provided or equal to 1.
 */
const calculateProgressPercentage = (totalNumberOfPages, barProgress) => {
  if (!barProgress || barProgress === 1) return -1;

  const percentage = totalNumberOfPages
    ? ((barProgress - 1) / (totalNumberOfPages - 1)) * 100
    : 0;

  if (percentage > 100) return 100;

  const addPercentage =
    totalNumberOfPages < 20
      ? 1
      : totalNumberOfPages < 25
      ? 0.95
      : totalNumberOfPages < 38
      ? 0.85
      : totalNumberOfPages < 60
      ? 0.8
      : totalNumberOfPages < 85
      ? 0.6
      : 0.5;

  return percentage <= 15
    ? percentage + addPercentage * 3 + addPercentage / 2
    : percentage <= 25
    ? percentage + addPercentage * 3
    : percentage && percentage < 35
    ? percentage + addPercentage * 2 + addPercentage / 2
    : percentage && percentage <= 50
    ? percentage + addPercentage * 2
    : percentage && percentage < 60
    ? percentage + addPercentage + addPercentage / 2
    : percentage && percentage <= 75
    ? percentage + addPercentage
    : percentage && percentage <= 85
    ? percentage + addPercentage / 2
    : percentage;
};

/**
 * Calculates the extra width needed for page indicators based on the total number of pages.
 * @param {number} totalNumberOfPages - Total number of pages.
 * @returns {number} - The calculated extra width in pixels.
 */
const extraWidth = (totalNumberOfPages) => {
  // at 54 is maximum for 100% widht to look ok
  const extraWidth = totalNumberOfPages - 54;
  const pageIndicatorWidht = 12; // in pixels

  if (extraWidth <= 0) return 0;

  return extraWidth * pageIndicatorWidht;
};

export const CandidateTestProgressBar = ({
  pages,
  progressPage,
  totalNumberOfPages,
  page,
  testName,
  timeLimit,
  onClick,
  notStarted,
  currentPage,
  lastPage,
  requiredAnswers,
  timeIsUp,
  submit,
}) => {
  const content = useContext(ContentContext) || {};
  const barProgress =
    page > 1 && page <= totalNumberOfPages && progressPage <= totalNumberOfPages
      ? progressPage
      : progressPage;
  const [progressWidth, setProgressWidth] = useState(0);
  const ref = useRef(null);
  useEffect(() => {
    setProgressWidth(ref.current ? ref.current.offsetWidth : 0);
  }, [progressWidth]);

  const progressPercentage = calculateProgressPercentage(
    totalNumberOfPages,
    barProgress
  );

  return (
    <Container>
      <Inner>
        <Space mobile />
        <TitleContainer>
          <Text>{content.currentTest}</Text>
          {currentPage && lastPage && !(currentPage > lastPage) && (
            <Text>
              {content.currentPage}: {currentPage}/{lastPage}
            </Text>
          )}
        </TitleContainer>
        <Text bold>{testName ? testName : content.test}</Text>
        <Space />
        <TimerContainer>
          {timeLimit && (
            <ProgressBarTimer
              notStarted={notStarted}
              time={timeLimit}
              timeIsUp={timeIsUp}
              submit={submit}
              testName={testName}
            />
          )}
        </TimerContainer>
        <ProgressContainer extraWidth={extraWidth(totalNumberOfPages)}>
          <ProgressLineContainer>
            <PercentIndicator progressWidthColorFill={progressPercentage} />
            {pages.map((page, key) => {
              return (
                <PageContainer key={key} ref={ref}>
                  <PageIndicator
                    unAnswered={page.unAnswered}
                    required={requiredAnswers}
                    current={currentPage === key + 1}
                    notSeen={progressPage < key + 1}
                    onClick={() =>
                      !requiredAnswers &&
                      page.unAnswered &&
                      onClick({ navigate: page.page })
                    }
                    data-done={page.page}
                    data-text={
                      content.unansweredPageText ||
                      'You have unanswered questions on the page'
                    }
                  />
                </PageContainer>
              );
            })}
          </ProgressLineContainer>
        </ProgressContainer>
      </Inner>
    </Container>
  );
};

const Container = styled.div`
  height: 18.6rem;
  background: ${(props) => props.theme.color.bgGrey};
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${device.mobileL} {
    height: 21.5rem;
    padding: 2rem;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Inner = styled.div`
  width: 67.2rem;
  height: 100%;
  flex-direction: column;
  position: relative;
  @media ${device.mobileL} {
    width: 100%;
  }
`;

const Text = styled.p`
  font-family: 'futura-pt', sans-serif;
  font-weight: ${(props) => (props.bold ? '500' : 'normal')};
  font-size: 2.4rem;
  line-height: 3.8rem;
  letter-spacing: 0.01em;
  width: fit-content;
  margin-top: ${(props) => (props.bold ? '0.5rem' : '0')};
  height: 3.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Space = styled.div`
  height: 2rem;
  @media ${device.mobileL} {
    ${(props) =>
      props.mobile &&
      css`
        height: 0px;
      `}
  }
`;

const PageContainer = styled.div`
  z-index: 99;
  position: relative;
`;

const PageIndicator = styled.div`
  height: 10px;
  width: 10px;
  margin: 0 1px;
  border-radius: 50%;
  position: relative;
  ${(props) =>
    props.unAnswered &&
    css`
      display: block;
      ${!props.required && 'cursor: pointer;'}
      ${props.current &&
        css`
          border: 1px solid ${props.theme.color.orange};
          outline: 1px solid ${props.theme.color.black};
        `}

      background: ${(props.notSeen && props.theme.color.selectedGrey) ||
        'black'};
    

      &:hover {
        &:after {
          content: attr(data-text) ': ' attr(data-done);
          display: block;
          position: absolute;
          bottom: 0;
          margin-bottom: 1.5rem;
          padding: 0.7rem;
          font-size: 1.6rem;
          line-height: 2rem;
          width: 20rem;
          background: ${(props) => props.theme.color.black};
          color: ${(props) => props.theme.color.white};
          border-radius: 0.6rem;
          position: absolute;
        }
      }
    `}
`;

const ProgressContainer = styled.div`
  background: ${(props) => props.theme.color.white};
  width: ${(props) => `calc(100% + ${props.extraWidth}px)`};
  margin-left: ${(props) => -(props.extraWidth / 2)}px;
  height: 3.6rem;
  border-radius: 2.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.6rem;
`;

const ProgressLineContainer = styled.div`
  width: 100%;
  height: 2.2rem;
  border-radius: 2.9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
  position: relative;
`;

const PercentIndicator = styled.div`
  background: ${(props) => props.theme.color.yellowOrange};
  width: 2.2rem;
  width: ${(props) => `${props.progressWidthColorFill}%`};
  height: 100%;
  border-radius: 2.9rem;
  position: absolute;
  top: 0;
  left: 0;
`;

const TimerContainer = styled.div`
  width: 13.2rem;
  height: 5.4rem;
  position: absolute;
  top: 4rem;
  right: 0;
  @media ${device.mobileL} {
    width: 8.5rem;
    height: 3.5rem;
    top: 14.8rem;
  }
`;
