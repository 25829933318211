import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Button } from '../../shared/button/Button';
import { Modal } from '../../shared/modal/Modal';
import { countries } from '../../shared/country-flags/countries';
import { plus } from '../../shared/assets/icons';
import { device } from '../../../styles/device';
import history from '../../../history';
import { useFetch } from '../../../helpers/hooks/fetchData';
import { DropDownSelect } from './DropDownSelect';

export const Card = ({
  addNew,
  code,
  flag,
  flagCard,
  language,
  slug,
  alreadyExistingLanguages,
}) => {
  const [showAddLanguageDropdown, setShowAddLanguageDropdown] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState({});
  const [payload, setPayload] = useState({});
  const [errorText, setErrorText] = useState('');
  const [openLanguageChoiceDropDown, setOpenLanguageChoiceDropDown] = useState(
    false
  );
  const [selectedItemText, setSelectedItemText] = useState('Select language');
  const [activeText, setActiveText] = useState(false);

  const selectLanguage = (id, language) => {
    setSelectedLanguage(
      countries[countries.findIndex((item) => item.id === id)]
    );
    setOpenLanguageChoiceDropDown(false);
    setSelectedItemText(language);
    setActiveText(true);
  };

  const { data, hasError } = useFetch(payload);

  useEffect(() => {
    if (hasError) {
      setErrorText('An error occurred.');
    }
    if (data) {
      return window.location.reload();
    }
  }, [data, hasError]);

  const onPostLanguageToDbSubmit = () => {
    setShowModal(true);

    setPayload({
      endpoint: '/api/omega/languages/',
      method: 'post',
      data: selectedLanguage,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  };

  const navigateToLanguageSinglePage = (slug) => {
    return history.push('/admin/languages/edit-language/' + slug);
  };

  return (
    <>
      {addNew ? (
        <Container>
          {showAddLanguageDropdown ? (
            <>
              <Close>
                <Button
                  onClick={() =>
                    setShowAddLanguageDropdown(!showAddLanguageDropdown)
                  }
                  text='x'
                />
              </Close>
              <Button
                onClick={() => onPostLanguageToDbSubmit()}
                text='Next'
                primary
              />
            </>
          ) : (
            <>
              <AddButton onClick={() => setShowModal(true)}>
                <PlusIcon src={plus} />
              </AddButton>
              <AddTitle>Add new language</AddTitle>
            </>
          )}
        </Container>
      ) : (
        <Container
          onClick={() => navigateToLanguageSinglePage(slug)}
          flagCard={flagCard}
        >
          <FlagContainer>
            <Flag src={flag} />
          </FlagContainer>
          <Language>{language}</Language>
          <Code>{code}</Code>
        </Container>
      )}
      <Modal
        show={showModal}
        handleExit={setShowModal}
        title='Add new language'
        width='40rem'
        removeOverFlowScroll
      >
        <ModalContent>
          <ModalTitle>Language</ModalTitle>
          {errorText && <ErrorMessage>{errorText}</ErrorMessage>}
          <Relative>
            <DropDownSelect
              onClick={() =>
                setOpenLanguageChoiceDropDown(!openLanguageChoiceDropDown)
              }
              isRotated={openLanguageChoiceDropDown}
              selectedItemText={selectedItemText}
              activeText={activeText}
            />

            {openLanguageChoiceDropDown ? (
              <LanguageVisibleContainer>
                <Ul>
                  {countries.map((option) => {
                    if (
                      alreadyExistingLanguages.findIndex(
                        (lang) => lang.code === option.code
                      ) !== -1
                    ) {
                      return false;
                    }
                    return (
                      <Li
                        key={option.id}
                        onClick={() =>
                          selectLanguage(option.id, option.language)
                        }
                      >
                        {option.language}
                      </Li>
                    );
                  })}
                </Ul>
              </LanguageVisibleContainer>
            ) : null}
          </Relative>
          <Spacing />
          <Button
            onClick={() => onPostLanguageToDbSubmit()}
            text='Save language'
            primary
          />
        </ModalContent>
      </Modal>
    </>
  );
};

const Container = styled.div`
  height: 9.4rem;
  width: 30rem;
  @media ${device.laptop} {
    width: 28rem;
  }
  @media ${device.mobileL} {
    width: 100%;
    margin-right: 0;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  border-radius: 0.4rem;
  background-color: ${(props) => props.theme.color.white};
  margin-right: ${(props) => props.theme.spacing.standard};
  margin-bottom: 6rem;
  ${(props) =>
    props.flagCard &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}
`;

const Close = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const ModalContent = styled.div`
  font-size: 4rem;
  flex-direction: column;
`;

const FlagContainer = styled.div`
  position: absolute;
  right: 2rem;
  top: -2.8rem;
  border-radius: 50%;
  height: 7.2rem;
  width: 7.2rem;
  overflow: hidden;
  box-shadow: 0px 0.05rem 1rem rgba(0, 0, 0, 0.12);
  background: ${(props) => props.theme.color.bgGrey};
`;

const Flag = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

const Language = styled.p`
  font-family: ${(props) => props.theme.font.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 2.1rem;
  padding-left: ${(props) => props.theme.spacing.standard};
  padding-bottom: ${(props) => props.theme.spacing.standard};
`;

const Code = styled.p`
  font-size: 1.7rem;
  padding-left: ${(props) => props.theme.spacing.standard};
`;

const AddTitle = styled.p`
  font-family: ${(props) => props.theme.font.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 2.1rem;
  padding-left: ${(props) => props.theme.spacing.standard};
  position: absolute;
  top: 20px;
`;

const AddButton = styled.div`
  position: absolute;
  right: 2rem;
  top: -2.8rem;
  border-radius: 50%;
  height: 7.2rem;
  width: 7.2rem;
  overflow: hidden;
  box-shadow: 0px 0.05rem 1rem rgba(0, 0, 0, 0.12);
  background: ${(props) => props.theme.color.noImgGrey};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const PlusIcon = styled.img`
  width: 3.4rem;
  height: 3.4rem;
  object-fit: cover;
`;

const ModalTitle = styled.p`
  font-family: ${(props) => props.theme.font.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.9rem;
  padding-bottom: ${(props) => props.theme.spacing.standard};
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 2rem;
  height: 3rem;
`;

const Spacing = styled.div`
  margin-bottom: 6.6rem;
`;

const Relative = styled.div`
  position: relative;
`;

const LanguageVisibleContainer = styled.div`
  max-height: 25.5rem;
  width: 100%;
  position: absolute;
  top: 7.3rem;
  left: 0;
  z-index: 2147483647;
  overflow: scroll;
  border-radius: 0.4rem;
`;

const Ul = styled.ul`
  width: 100%;
  background: ${(props) => props.theme.color.bgGrey};
  font-size: 1.6rem;
  line-height: 4rem;
  border-radius: 0.4rem;
  padding: ${(props) => props.theme.spacing.extraSmall};
  @media ${device.mobileM} {
    font-size: 1.4rem;
  }
`;
const Li = styled.li`
  width: 100%;
  height: 4rem;
  padding-left: ${(props) => props.theme.spacing.small};
  border-radius: 0.4rem;

  &:hover {
    background: ${(props) => props.theme.color.selectedGrey};
    color: ${(props) => props.theme.color.white};
    cursor: pointer;
    border-radius: 0.4rem;
  }
`;
