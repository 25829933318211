import React from 'react';
import styled, { css } from 'styled-components';
import { eyeGrey } from '../../shared/assets/icons';
import { device } from '../../../styles/device';

export const EmailFieldGroup = ({ children, label, previewText, textarea }) => {
  return (
    <>
      <Label>{label}</Label>
      <InputAndPreviewWrapper>
        <PreviewBox textarea={textarea}>
          <PreviewText>{previewText}</PreviewText>
          <Icon src={eyeGrey} />
        </PreviewBox>
        <Border />
        {children}
      </InputAndPreviewWrapper>
    </>
  );
};

const Label = styled.p`
  font-family: ${(props) => props.theme.font.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  padding-bottom: 1.5rem;
`;

const InputAndPreviewWrapper = styled.div`
  background: ${(props) => props.theme.color.bgGrey};
  width: 100%;
  border-radius: 0.4rem;
  overflow: hidden;
`;

const PreviewBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  min-height: 6rem;
  padding-left: 1.5rem;
  line-height: 2.1rem;
  ${(props) =>
    props.textarea &&
    css`
      min-height: fit-content;
      padding: 2rem 0 2rem 1.5rem;
    `}
`;

const PreviewText = styled.div`
  font-family: ${(props) => props.theme.font.primary};
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  color: #747474;
  width: 100%;
  margin-right: 4.5rem;
  @media ${device.mobileM} {
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin-right: 3rem;
  }
`;

const Icon = styled.img`
  position: absolute;
  right: 2rem;
`;

const Border = styled.div`
  border: 0.5px solid #d6d6d6;
  margin: 0 1.5rem;
`;
