import React, { useCallback, useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { Test } from '../../../components/candidate/test';
import { CandidatePageButton } from '../../../components/shared/button/CandidatePageButton';
import { CandidateTestProgressBar } from '../../../components/shared/progress-bar/CandidateTestProgressBar';
import { device } from '../../../styles/device';
import { useFetch } from '../../../helpers/hooks/fetchData';
import { InfoPage } from './info-page';
import history from '../../../history';
import { ContentContext } from '../../../helpers/contentContext';
import { getIndexFromPath } from '../../../helpers/common';

export const DoTest = (location) => {
  const index = getIndexFromPath(location.location.pathname);
  const content = useContext(ContentContext) || {};
  const [payload, setPayload] = useState({});
  const [submitPayload, setSubmitPayload] = useState({});
  const [submitData, setSubmitData] = useState({});
  const [params] = useState({ index });
  const [submitEndpoint, setSubmitEndpoint] = useState(false);
  const answers = [];
  const endpoint = '/api/omega/candidate-test/get-questions';
  const method = 'get';
  const submitMethod = 'post';
  const [questionsData, setQuestionsData] = useState([]);
  const [language, setLanguage] = useState([]);
  const [page, setPage] = useState([]);
  const [isLoading, setIsLoading] = useState(null);
  const [isRequired, setIsRequired] = useState(false);
  const [timeLimit, setTimeLimit] = useState(null);
  const [timeIsUp, setTimeIsUp] = useState(false);
  const [allowedAnswers, setAllowedAnswers] = useState(null);
  const [allAnswered, setAllAnswered] = useState(
    questionsData && questionsData.questions
      ? answers.length === questionsData.questions.length
      : false
  );

  useEffect(() => {
    setPayload({ endpoint, method, params });
  }, [endpoint, method, params]);

  useEffect(() => {
    setSubmitPayload({
      endpoint: submitEndpoint,
      method: submitMethod,
      data: submitData,
      params: { index },
    });
  }, [submitEndpoint, submitMethod, submitData, index]);

  const { data, isLoading: initialLoading, hasError } = useFetch(payload);
  const { data: submitResp, isLoading: submitIsLoading } = useFetch(
    submitPayload
  );

  useEffect(() => {
    if (data && data.questions) {
      setAllowedAnswers(data.allowedAnswers);
      setQuestionsData({
        category: data.category,
        sections: data.sections ? data.sections : '',
        questions: data.questions,
        currentAnswers: data.currentAnswers,
        testInfo: data.testInfo,
        questionNumber: data.questionNumber,
        allowedAnswers: data.allowedAnswers,
      });
    }

    if (data && data.dueDate) {
      setTimeLimit(data.dueDate);
    }
    if (data && data.language) {
      setLanguage(data.language);
    }
    if (data && data.page) {
      setPage(data.page);
    }
    if (data && data.isRequired) {
      setIsRequired(data.isRequired);
    }
    if ((data && data.timeIsUp) || (data && data.isCompleted)) {
      return history.push(`/candidate/test/thank-you/${index}`);
    }
    if (data === false) {
      return history.push('/');
    }
  }, [data, language, index]);

  useEffect(() => {
    if (timeIsUp) {
      if (
        submitResp &&
        submitResp.questionsData &&
        submitResp.questionsData.currentAnswers !== questionsData.currentAnswers
      ) {
        setQuestionsData({
          ...questionsData,
          category: submitResp.category,
          sections: submitResp.questionsData.sections,
          questions: submitResp.questionsData.questions,
          currentAnswers: submitResp.questionsData.currentAnswers,
        });
      }

      return;
    }

    if (submitResp && submitResp.isCompleted) {
      return history.push(`/candidate/test/thank-you/${index}`);
    }

    if (
      submitResp &&
      submitResp.answersSaved &&
      isLoading &&
      !submitIsLoading
    ) {
      if (submitResp && submitResp.infoPage) {
        setQuestionsData({
          ...questionsData,
          category: submitResp.category,
          questions: [],
          testInfo: submitResp.testInfo,
          questionsRemaning: submitResp.questionsRemaning,
        });
        setPage(submitResp.page);
      }

      submitResp.answersSaved = false;
      setIsLoading(false);
      answers.length = 0;
      if (submitResp.questionsData.questions) {
        setQuestionsData({
          ...questionsData,
          category: submitResp.category,
          sections: submitResp.questionsData.sections,
          questions: submitResp.questionsData.questions,
          currentAnswers: submitResp.questionsData.currentAnswers,
          testInfo: submitResp.questionsData.testInfo,
          questionNumber: submitResp.questionNumber,
          allowedAnswers,
        });
      }

      if (submitResp.questionsData.dueDate) {
        setTimeLimit(submitResp.questionsData.dueDate);
      }

      if (submitResp.questionsData.page) {
        setPage(submitResp.questionsData.page);
      }

      window.scrollTo(0, 0);
    } else if (submitResp && submitResp.timeIsUp) {
      return history.push(`/candidate/test/thank-you/${index}`);
    }

    if (
      submitResp &&
      submitResp.questionsData &&
      submitResp.questionsData.page
    ) {
      setPage(submitResp.questionsData.page);
    }
  }, [
    submitResp,
    answers.length,
    isLoading,
    submitIsLoading,
    questionsData,
    index,
    allowedAnswers,
    timeIsUp,
  ]);

  const checkOmegaAnswers = () => {
    let allAnswered = answers.length === questionsData.questions.length;

    answers.forEach((answer) => {
      if (allAnswered) {
        allAnswered =
          answer.answer['1'] && answer.answer['2'] && answer.answer['3']
            ? true
            : false;
      }
    });

    return allAnswered;
  };

  const checkOppositesAndAnalogiesAnswers = () => {
    let allAnswered = answers.length === questionsData.questions.length;

    if (allAnswered) {
      const answeredLength = answers.filter(
        (question) => question.answer && question.answer.length > 1
      ).length;
      allAnswered = answeredLength === answers.length;
    }

    return allAnswered;
  };

  const submitAnswer = (payload) => {
    const questionIx = answers.findIndex(
      (item) => item.questionId === payload.questionId
    );

    if (questionIx !== -1) answers.splice(questionIx, 1);
    answers.push(payload);

    setAllAnswered(
      questionsData.questions &&
        questionsData.category &&
        questionsData.category.includes('omega')
        ? checkOmegaAnswers()
        : questionsData.questions &&
          questionsData.category &&
          ['opposites', 'analogies'].includes(questionsData.category)
        ? checkOppositesAndAnalogiesAnswers()
        : questionsData.questions
        ? answers.length === questionsData.questions.length
        : false
    );
  };

  useEffect(() => {
    if (questionsData) {
      setAllAnswered(
        questionsData.questions
          ? answers.length === questionsData.questions.length
          : false
      );
    }
  }, [questionsData, answers.length]);

  const submitAndNavigate = useCallback(
    ({ navigate, timeRanOut }) => {
      if (page !== navigate && !timeIsUp) {
        setIsLoading(true);
        setQuestionsData(null);
        setPage(null);
      }

      if (timeRanOut) {
        setQuestionsData((pre) => {
          pre.currentAnswers = answers;
          return { ...pre };
        });
      }

      setSubmitData({ answers, navigate, timeRanOut });
      setSubmitEndpoint('/api/omega/candidate-test/submit-answers');
    },
    [
      page,
      timeIsUp,
      setIsLoading,
      setQuestionsData,
      setPage,
      setSubmitData,
      setSubmitEndpoint,
      answers,
    ]
  );

  useEffect(() => {
    if (!data && hasError) {
      return history.push('/');
    }
  }, [hasError, data]);

  const handleTimeUp = useCallback(() => {
    if (timeIsUp) return;

    setTimeIsUp(true);
    submitAndNavigate({
      navigate: page ? page : 'next',
      timeRanOut: true,
    });
  }, [submitAndNavigate, page, timeIsUp]);

  return (
    <>
      {questionsData &&
        questionsData.questions &&
        !initialLoading &&
        !submitIsLoading && (
          <>
            <CandidateTestProgressBar
              progressPage={questionsData.testInfo.progress}
              totalNumberOfPages={questionsData.testInfo.pages}
              pages={questionsData.testInfo.pagesArray}
              timeLimit={timeLimit}
              page={page}
              onClick={submitAndNavigate}
              testName={
                questionsData.testInfo.titles[content.langCode] ||
                questionsData.testInfo.title
              }
              currentPage={page}
              lastPage={
                questionsData &&
                questionsData.testInfo &&
                questionsData.testInfo.pages
              }
              requiredAnswers={isRequired}
              setTimeIsUp={setTimeIsUp}
              timeIsUp={timeIsUp}
              submit={() => handleTimeUp()}
            />

            {!!questionsData.questions.length && questionsData.currentAnswers && (
              <TestContainer>
                {(questionsData.category === 'omega 1' ||
                  questionsData.category === 'omega 2') && (
                  <InstructionContainer>
                    {content.omegaLikertScaleExplanation}
                  </InstructionContainer>
                )}
                {!isLoading && !submitIsLoading && (
                  <Test
                    questionsData={questionsData}
                    language={language}
                    submitAnswer={submitAnswer}
                  />
                )}
                <ButtonContainer>
                  {page > 1 && (
                    // TODO: add a flag in template if "display-backbutton" or some instead of checking category
                    // questionsData &&
                    // questionsData.category &&
                    // !questionsData.category.includes('omega') &&
                    <ButtonWidth>
                      <CandidatePageButton
                        text={content.previousQuestion}
                        onClick={() => {
                          submitAndNavigate({
                            navigate: page ? page - 1 : 1,
                          });
                        }}
                        secondary
                      />
                    </ButtonWidth>
                  )}
                  <Space />
                  <ButtonWidth>
                    <CandidatePageButton
                      text={content.nextQuestion}
                      disabled={isRequired && !allAnswered}
                      onClick={() => {
                        submitAndNavigate({
                          navigate: page ? page + 1 : 'next',
                        });
                      }}
                      primary
                    />
                  </ButtonWidth>
                </ButtonContainer>
              </TestContainer>
            )}
            {!questionsData.questions.length &&
              questionsData.questionsRemaning && (
                <InfoPage
                  index={index}
                  language={language}
                  questionsRemaning={questionsData.questionsRemaning}
                  sections={submitResp.sections}
                  isCompleted={submitResp.allQuestionsAnswered}
                  onClick={submitAndNavigate}
                  timedTest={data && data.dueDate}
                />
              )}
          </>
        )}
    </>
  );
};

const InstructionContainer = styled.div`
  display: flex;
  color: #c8c8c8;
  align-items: center;
  flex-direction: column;
  font-size: 2.2rem;
  line-height: 160%;
  font-style: italic;
`;
const TestContainer = styled.div`
  background: ${(props) => props.theme.color.white};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5rem;
  flex-direction: column;
  @media ${device.mobileL} {
    padding-top: 2rem;
  }
`;

const ButtonWidth = styled.div`
  width: 20.4rem;
  @media ${device.mobileL} {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-bottom: 11rem;
  margin-top: 4rem;
  @media ${device.mobileL} {
    flex-direction: column;
    width: 100%;
    padding: 0 2rem;
  }
`;

const Space = styled.div`
  width: 3rem;
  @media ${device.mobileL} {
    height: 2.5rem;
  }
`;

// const ErrorMessage = styled.p`
//   margin-top: 13rem;
//   height: 4rem;
//   font-family: 'futura-pt', sans-serif;
//   font-size: 2.2rem;
//   line-height: 3.5rem;
//   color: ${(props) => props.theme.color.error};
//   @media ${device.mobileL} {
//     text-align: center;
//     font-size: 1.9rem;
//     line-height: 2.2rem;
//     padding: 0 2.5rem;
//     margin-top: 8rem;
//   }
// `;
