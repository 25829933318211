import React, { Fragment } from 'react';
import styled from 'styled-components';
import { InputWrapper } from '../../../shared/input/InputWrapper';
import { minusBox, plusBlack } from '../../../shared/assets/icons';
import { SelectInput } from '../../../shared/input/SelectInput';
import { FormLabel } from '../../../shared/input/FormLabel';

export const EditPersonalityScore = ({
  sections,
  setSections,
  section,
  sectionIndex,
  clusters,
}) => {
  const addNewDiagram = () => {
    sections[sectionIndex].data.personalityScore.push('');
    setSections([...sections]);
  };

  const updateCluster = (index, value) => {
    sections[sectionIndex].data.personalityScore[index] = value;
    setSections([...sections]);
  };

  const removeCluster = (index) => {
    sections[sectionIndex].data.personalityScore.splice(index, 1);
    setSections([...sections]);
  };

  return (
    <Fragment>
      <InputWrapper noPadding noPaddingLeft>
        <InnerWrapper>
          <GridCoulumn>
            <Text>Clusters</Text>
          </GridCoulumn>
          <GridCoulumn>
            <Icon alignEnd onClick={addNewDiagram} src={plusBlack} />
          </GridCoulumn>

          {section.data &&
            section.data.personalityScore &&
            section.data.personalityScore.length &&
            section.data.personalityScore.map((row, index) => (
              <Fragment key={index}>
                <GridCoulumn marginBottom>
                  {clusters && clusters.length && (
                    <Fragment>
                      <LabelContainer>
                        <FormLabel label='Cluster' />
                        <IconContainer>
                          <Icon
                            alignEnd
                            onClick={() => removeCluster(index)}
                            src={minusBox}
                          />
                        </IconContainer>
                      </LabelContainer>
                      <SelectInput
                        data={clusters}
                        placeholder='Pick cluster'
                        value={row}
                        onChange={({ target }) =>
                          updateCluster(index, target.value)
                        }
                      />
                    </Fragment>
                  )}
                </GridCoulumn>
              </Fragment>
            ))}
        </InnerWrapper>
      </InputWrapper>
    </Fragment>
  );
};

const InnerWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  justify-content: center;
`;

const GridCoulumn = styled.div`
  display: grid;
  ${(props) => props.marginBottom && 'margin-bottom: 1rem;'};
`;

const Text = styled.p`
  padding-left: ${(props) => props.theme.spacing.standard};
  font-family: ${(props) => props.theme.font.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.9rem;
`;

const Icon = styled.img`
  height: 2rem;
  width: 2rem;
  margin-left: ${(props) => props.theme.spacing.standard};
  ${(props) => props.alignEnd && 'margin-left: auto;'}
  ${(props) => props.onClick && 'cursor: pointer;'}
`;

const IconContainer = styled.div`
  display: flex;
  margin-left: auto;
  margin-bottom: 1.5rem;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`;
