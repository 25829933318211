import React, { Fragment } from 'react';
import styled from 'styled-components';
import { InputWrapper } from '../../../shared/input/InputWrapper';
import { SelectInput } from '../../../shared/input/SelectInput';
import { Languages } from '../../questions/edit-question/Languages';

export const EditFourFielder = ({
  sections,
  setSections,
  section,
  sectionIndex,
  clusters,
  availableLanguages,
  errors,
  touched,
  handleBlur,
}) => {
  const updateCluster = (value, isXvalue) => {
    if (isXvalue) sections[sectionIndex].data.fourFielder.x.cluster = value;
    if (!isXvalue) sections[sectionIndex].data.fourFielder.y.cluster = value;
    setSections([...sections]);
  };

  const handleTextChange = (
    target,
    sectionIndex,
    lang,
    isLowValue,
    isXvalue
  ) => {
    const value = target.value;

    if (isXvalue && isLowValue === 'left')
      sections[sectionIndex].data.fourFielder.x.lowValue[lang.code] = value;
    if (isXvalue && isLowValue === 'right')
      sections[sectionIndex].data.fourFielder.x.highValue[lang.code] = value;

    if (!isXvalue && isLowValue === 'left')
      sections[sectionIndex].data.fourFielder.y.lowValue[lang.code] = value;
    if (!isXvalue && isLowValue === 'right')
      sections[sectionIndex].data.fourFielder.y.highValue[lang.code] = value;

    setSections([...sections]);
  };

  return (
    <Fragment>
      <InputWrapper noPadding noPaddingLeft>
        <InnerWrapper>
          {section.data && section.data.fourFielder && (
            <Fragment>
              <GridCoulumn>
                <Text>X value</Text>
                <SelectInput
                  data={clusters}
                  placeholder='Pick cluster'
                  value={section.data.fourFielder.x.cluster}
                  onChange={({ target }) => updateCluster(target.value, true)}
                />
                <Languages
                  availableLanguages={availableLanguages}
                  errors={errors}
                  touched={touched}
                  onBlur={handleBlur}
                  values={section.data.fourFielder.x}
                  sectionIndex={sectionIndex}
                  onChange={handleTextChange}
                  rowIndex={true}
                  scatterplotLow
                />{' '}
                <Languages
                  availableLanguages={availableLanguages}
                  errors={errors}
                  touched={touched}
                  onBlur={handleBlur}
                  values={section.data.fourFielder.x}
                  sectionIndex={sectionIndex}
                  onChange={handleTextChange}
                  rowIndex={true}
                  scatterplotHigh
                />
              </GridCoulumn>
              <GridCoulumn>
                <Text>Y value</Text>
                <SelectInput
                  data={clusters}
                  placeholder='Pick cluster'
                  value={section.data.fourFielder.y.cluster}
                  onChange={({ target }) => updateCluster(target.value)}
                />
                <Languages
                  availableLanguages={availableLanguages}
                  errors={errors}
                  touched={touched}
                  onBlur={handleBlur}
                  values={section.data.fourFielder.y}
                  sectionIndex={sectionIndex}
                  onChange={handleTextChange}
                  scatterplotLow
                />
                <Languages
                  availableLanguages={availableLanguages}
                  errors={errors}
                  touched={touched}
                  onBlur={handleBlur}
                  values={section.data.fourFielder.y}
                  sectionIndex={sectionIndex}
                  onChange={handleTextChange}
                  scatterplotHigh
                />
              </GridCoulumn>
            </Fragment>
          )}
        </InnerWrapper>
      </InputWrapper>
    </Fragment>
  );
};

const InnerWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  justify-content: center;
`;

const GridCoulumn = styled.div`
  display: grid;
  ${(props) => props.marginBottom && 'margin-bottom: 1rem;'};
`;

const Text = styled.p`
  // padding-left: ${(props) => props.theme.spacing.standard};
  font-family: ${(props) => props.theme.font.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.9rem;
`;
