import React, { Fragment } from 'react';
import styled from 'styled-components';
import { InputWrapper } from '../../../shared/input/InputWrapper';
import { plusBlack, minusBox } from '../../../shared/assets/icons';
import { Languages } from '../../questions/edit-question/Languages';
import { Type } from '../../questions/edit-question/Type';
import { SelectInput } from '../../../shared/input/SelectInput';

export const EditScatterplotRow = ({
  availableLanguages,
  sections,
  setSections,
  section,
  sectionIndex,
  errors,
  touched,
  handleBlur,
  clusters,
  removeSection,
}) => {
  const addNewDiagram = (ix) => {
    sections[ix].data.scatterplotRows.push({
      lowValue: {},
      highValue: {},
      cluster: '',
    });
    setSections([...sections]);
  };

  const removeScatterRow = (ix, rowIx) => {
    sections[ix].data.scatterplotRows.splice(rowIx, 1);
    setSections([...sections]);
  };

  const handleSectionChange = (target, index, lang, option, rowIndex) => {
    switch (option) {
      case 'title':
        if (!sections[index].title[lang.code])
          sections[index].title[lang.code] = {};
        sections[index].title[lang.code] = target.value;
        break;
      case 'left':
        if (!sections[index].data.scatterplotRows[rowIndex].lowValue[lang.code])
          sections[index].data.scatterplotRows[rowIndex].lowValue[
            lang.code
          ] = {};

        sections[index].data.scatterplotRows[rowIndex].lowValue[lang.code] =
          target.value;
        break;
      case 'right':
        if (
          !sections[index].data.scatterplotRows[rowIndex].highValue[lang.code]
        )
          sections[index].data.scatterplotRows[rowIndex].highValue[
            lang.code
          ] = {};
        sections[index].data.scatterplotRows[rowIndex].highValue[lang.code] =
          target.value;
        break;
      case 'cluster':
        sections[index].data.scatterplotRows[rowIndex].cluster = target.value;
        break;
      case 'median':
        sections[index].median = target;
        break;
      case 'single-data':
        sections[index].singleData = target;
        break;
      default:
        break;
    }

    setSections([...sections]);
  };

  return (
    <Fragment key={`${sectionIndex}`}>
      <InputWrapper noPadding noPaddingLeft>
        <InnerWrapper>
          <GridCoulumn>
            <Text>Show median?</Text>
            <Type
              chosenType={section.median}
              setChosenType={(target) =>
                handleSectionChange(target, sectionIndex, '', 'median')
              }
              yesOrNoOption
            />
          </GridCoulumn>
          <GridCoulumn>
            <Text>Single data?</Text>
            <Type
              chosenType={section.singleData}
              setChosenType={(target) =>
                handleSectionChange(target, sectionIndex, '', 'single-data')
              }
              yesOrNoOption
            />
          </GridCoulumn>
          {/* TODO: add choice for diagram */}
        </InnerWrapper>
      </InputWrapper>
      <InputWrapper noPadding noPaddingLeft>
        <InnerWrapper>
          <GridCoulumn>
            <Text>Rows</Text>
          </GridCoulumn>
          <GridCoulumn>
            <Icon
              alignEnd
              onClick={() => addNewDiagram(sectionIndex)}
              src={plusBlack}
            />
          </GridCoulumn>
          {section.data &&
            section.data.scatterplotRows.length &&
            section.data.scatterplotRows.map((row, index) => (
              <Fragment key={index}>
                <GridCoulumn marginBottom>
                  {clusters && clusters.length && (
                    <SelectInput
                      label='Cluster'
                      data={clusters}
                      placeholder='Pick cluster'
                      value={row.cluster}
                      onChange={({ target }) =>
                        handleSectionChange(
                          target,
                          sectionIndex,
                          '',
                          'cluster',
                          index
                        )
                      }
                    />
                  )}
                </GridCoulumn>
                <GridCoulumn>
                  <IconContainer>
                    <Icon
                      alignEnd
                      onClick={() => removeScatterRow(sectionIndex, index)}
                      src={minusBox}
                    />
                  </IconContainer>
                </GridCoulumn>
                <GridCoulumn>
                  <Languages
                    availableLanguages={availableLanguages}
                    errors={errors}
                    touched={touched}
                    values={row}
                    sectionIndex={sectionIndex}
                    rowIndex={index}
                    onChange={handleSectionChange}
                    onBlur={handleBlur}
                    scatterplotLow
                  />
                </GridCoulumn>
                <GridCoulumn>
                  <Languages
                    availableLanguages={availableLanguages}
                    errors={errors}
                    touched={touched}
                    values={row}
                    sectionIndex={sectionIndex}
                    rowIndex={index}
                    onChange={handleSectionChange}
                    onBlur={handleBlur}
                    scatterplotHigh
                  />
                </GridCoulumn>
              </Fragment>
            ))}
        </InnerWrapper>
        {removeSection}
      </InputWrapper>
    </Fragment>
  );
};

const InnerWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  justify-content: center;
`;

const GridCoulumn = styled.div`
  display: grid;
  ${(props) => props.marginBottom && 'margin-bottom: 1rem;'};
`;

const Text = styled.p`
  padding-left: ${(props) => props.theme.spacing.standard};
  font-family: ${(props) => props.theme.font.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.9rem;
`;

const Icon = styled.img`
  height: 2rem;
  width: 2rem;
  margin-left: ${(props) => props.theme.spacing.standard};
  ${(props) => props.alignEnd && 'margin-left: auto;'}
  ${(props) => props.onClick && 'cursor: pointer;'}
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;
